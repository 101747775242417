import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { catchError, map, retry, tap } from 'rxjs/operators';
import { environment } from 'src/environments/environment';
import { MainService } from 'src/app/app.service';


@Injectable({
  providedIn: 'root'
})
export class ChatbotService {
  private apiUrl = environment.API_CHAT;

  constructor(private http: HttpClient, private mainsService: MainService) { }

  enviarPergunta(pergunta: string) {
    return this.http.post<any>(this.apiUrl, { msg: pergunta })
    .pipe(
      retry(3),
      map(resposta => {
          return resposta;
      }),
      catchError(this.mainsService.handleError));
  }

  limparhistorico() {
    return this.http.post<any>(this.apiUrl, {})
    .pipe(
      retry(3),
      map(resposta => {
          return resposta;
      }),
      catchError(this.mainsService.handleError));
  }
}
