<div class="po-row">
    <po-input
      class="po-md-12"
      name="Nome"
      [(ngModel)]="name"
      p-label="Nome"
      (p-change)="onChange()">
    </po-input>
  </div>

  <div class="po-row">
    <po-radio-group
      class="po-lg-8"
      p-columns="3"
      name="Expiração"
      p-label="Prorrogar por () dias:"
      [p-options]="expiraOptions"
      [(ngModel)]="expirationDays"
      (p-change)="onChange()">
    </po-radio-group>
  </div>