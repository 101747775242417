<head>
  <!-- Matomo Tag Manager -->
  <script>
    var _mtm = window._mtm = window._mtm || [];
    _mtm.push({'mtm.startTime': (new Date().getTime()), 'event': 'mtm.Start'});
    var d=document, g=d.createElement('script'), s=d.getElementsByTagName('script')[0];
    g.async=true; g.src='http://10.171.80.34:9603/js/container_akjXKwvF.js'; s.parentNode.insertBefore(g,s);
    </script>
    <!-- End Matomo Tag Manager -->
  </head>

<div class="po-wrapper">

    <po-toolbar 
      p-title="EngPro Service Provider"
      p-actions-icon="po-icon-help"
      [p-profile-actions]="profileActions"
      p-profile-icon="po-icon-user"
      >
    </po-toolbar>
  
    <po-menu
      [p-menus]="menu"
      p-logo="../../../assets/images/menu-header.png"
      p-short-logo="../../favicon.ico">
    </po-menu>
  
    <router-outlet></router-outlet>
  </div>
  
  <po-modal #cadastraArea 
    p-title="Cadastro de área"
    [p-primary-action]="saveArea">
  
    <div class="po-row">
      <po-info
        class="po-md-9 thf-mb-sm-6 thf-mb-md-9 thf-lb-lg-9"
        p-label="Área atual"
        p-orientation="horizontal"
        [p-value]="actualArea">
      </po-info>
    </div>

    <div class="po-row">
      <po-select
        class="po-lg-6"
        name="listaArea"
        [(ngModel)]="areaSelected"
        p-label="Área de trabalho"
        [p-required]= "true"
        [p-options]="areas">
      </po-select>
    </div>
    
  </po-modal>

  <po-modal #getPATAToken 
  p-title="Token - VS Code"
  p-click-out=true>

  <div class="sample-container" *ngIf="tokenExists">
    
    <po-info
      class="po-md-9 thf-mb-sm-6 thf-mb-md-9 thf-lb-lg-9"
      p-label="Usuário"
      p-orientation="horizontal"
      [p-value]="tokenUser">
    </po-info>

    <po-info
      class="po-md-9 thf-mb-sm-6 thf-mb-md-9 thf-lb-lg-9"
      p-label="Data de criação"
      p-orientation="horizontal"
      [p-value]="tokenDate">
    </po-info>

    <po-textarea
      class="po-sm-12"
      name="token"
      [(ngModel)]="tokenData"
      p-label="Token:"
      p-readonly
      p-rows="4">
    </po-textarea>

    <br>

    <po-button
      class="po-lg-12 po-mt-2"
      (p-click)="deletePATAToken()"
      p-label="Deletar Token!" >
    </po-button>

  </div>

  <div class="sample-container" *ngIf="!tokenExists">
    <po-info
      class="po-md-9 thf-mb-sm-6 thf-mb-md-9 thf-lb-lg-9"
      p-label="Para gerar o token preencha as informações abaixo:">
    </po-info>
    <br>
    <po-input
      class="po-md-12"
      p-clean
      p-icon="po-icon-user"
      p-label="Username"
      [(ngModel)]="tUsername">
    </po-input>

    <po-password
      class="po-sm-12"
      [(ngModel)]="tPassword"
      p-clean
      p-label="Senha"
      p-required>
    </po-password>
    
    <po-button
      class="po-lg-12 po-mt-2"
      (p-click)="generatePATAToken()"
      p-label="Gerar Token!" >
    </po-button>

    <po-textarea
      class="po-sm-12"
      name="token"
      [(ngModel)]="tokenData"
      p-label="Token:"
      p-readonly
      p-rows="4">
    </po-textarea>
  </div>
  
</po-modal>

<po-modal #getCompileKey
  p-title="Compile Key"
  p-click-out=true>

  <div class="sample-container" *ngIf="compileKey">

    <po-info
      class="po-md-9 thf-mb-sm-6 thf-mb-md-9 thf-lb-lg-9"
      p-label="Emitido por"
      p-orientation="horizontal"
      [p-value]="compileKey.payload.iss">
    </po-info>

    <po-info
      class="po-md-9 thf-mb-sm-6 thf-mb-md-9 thf-lb-lg-9"
      p-label="Emitido para"
      p-orientation="horizontal"
      [p-value]="compileKey.payload.sub">
    </po-info>

    <po-info
      class="po-md-9 thf-mb-sm-6 thf-mb-md-9 thf-lb-lg-9"
      p-label="Tipo"
      p-orientation="horizontal"
      [p-value]="compileKey.payload.auth">
    </po-info>

    <po-info
      class="po-md-9 thf-mb-sm-6 thf-mb-md-9 thf-lb-lg-9"
      p-label="Vencimento"
      p-orientation="horizontal"
      [p-value]="compileKey.payload.exp">
    </po-info>

    <po-textarea
      class="po-sm-12"
      name="compileKey"
      [(ngModel)]="compileKey.token"
      p-label="Compile Key"
      p-readonly
      p-rows="4">
    </po-textarea>
    <br>
  </div>

  <div class="sample-container" *ngIf="!compileKey">
  	<po-loading-overlay></po-loading-overlay>
  </div>
</po-modal>