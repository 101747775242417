import { Component, ElementRef, ViewChild } from '@angular/core';
import { MainService } from 'src/app/app.service';
import { ChatbotService } from './chatbot.service';

@Component({
  selector: 'app-chatbot-ai',
  templateUrl: './chatbot-ai.component.html',
  styleUrls: ['./chatbot-ai.component.css']
})
export class ChatbotAiComponent {
  @ViewChild('scrollTarget') private chat: ElementRef;
  
  pergunta: string = '';
  resposta: string = '';
  messages: Object[] = [{'content': '', 'class': ''}]
  message: string = ''
  chatSize: number = 0;

  
  constructor(
    private chatbotService: ChatbotService,
    public mainService: MainService,
  ) {
  }

  ngOnInit() {
  }

  criaBolhaUsuario(): Object {
    const bolha = {'content': '', 'class': 'chat__bolha chat__bolha--usuario', 'icon': '' };
    return bolha;
  }

  criaBolhaBot(): Object {
    const bolha = {'content': '', 'class': 'chat__bolha chat__bolha--bot', 'icon': '' };
    return bolha;
  }

  vaiParaFinalDoChat(messageNumber) {
    if (this.chat?.nativeElement) {
      try {
        setTimeout(() => {
          const element = this.chat.nativeElement.querySelector(`#${messageNumber}`);
          if (element) {
            element.scrollIntoView({ behavior: 'smooth' });
          }
        }, 50);
      } catch (err) {
        console.error('Scroll to element failed', err);
      }
    }
  }

  enviarPergunta() {

    const mensagem = this.pergunta;
    this.pergunta = '';

    const novaBolha = this.criaBolhaUsuario();
    novaBolha['content'] = mensagem;
    this.messages.push(novaBolha);

    const novaBolhaBot = this.criaBolhaBot();
    this.chatSize = this.messages.length - 1
    this.vaiParaFinalDoChat("message-" + this.chatSize);

    this.chatbotService.enviarPergunta(mensagem)
      .subscribe({
        next: ((resposta) => {
          novaBolhaBot['content'] = resposta['content'];
          this.messages.push(novaBolhaBot)
          this.chatSize = this.messages.length - 1
          this.vaiParaFinalDoChat("message-" + this.chatSize);
          }),
          error: ((msgError) => {
            this.mainService.handleError(msgError);
          })
      });
  }
}
