import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PoModule } from '@po-ui/ng-components';

@Component({
  selector: 'app-share-resource',
  templateUrl: './share-resource.component.html',
  styleUrls: ['./share-resource.component.css'],
  standalone: true,
  imports: [
    FormsModule,
    CommonModule,
    PoModule]
})
export class ShareResourceComponent {

  @Input() usersSelected: Array<any> = []
  @Input() usersInArea: Array<any> = []
  @Output() saveUserShared = new EventEmitter<{ usersSelected: Array<any> }>();

  updateSharedUsers() {
    this.saveUserShared.emit({
      usersSelected: this.usersSelected
    });
  }
}
