<div class="po-row">
    <po-info p-label="Usuários compartilhados" class="po-md-12" [p-value]="usersSelected" ></po-info>
  </div>

  <div class="po-row">
    <po-multiselect
      name="usersSelected"
      class="po-md-12"
      [(ngModel)]="usersSelected"
      label="Usuários"
      [p-options]="usersInArea"
      (p-change)="updateSharedUsers()"
      p-required>
    </po-multiselect>

  </div>