import { CommonModule } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { PoDynamicFormField, PoModule } from '@po-ui/ng-components';
import { PoTemplatesModule } from '@po-ui/ng-templates';

@Component({
  selector: 'app-create-resource-form',
  templateUrl: './create-resource-form.component.html',
  styleUrls: ['./create-resource-form.component.css'],
  standalone: true,
  imports: [FormsModule,
            CommonModule,
            PoModule,
            PoTemplatesModule]
})
export class CreateResourceFormComponent {

  @Input() hasWarning: boolean = false;
  @Input() warnText: string = ''
  @Input() form_fields: Array<PoDynamicFormField> = []
  @Input() form_validate: (field: any) => true;
  @Input() validate_fields: Array<String> = [''];
  @Output() form_values = new EventEmitter<any>();

  loading: boolean = false;

  form_validateMain(received) {
    this.form_validate(received);
  }
}
