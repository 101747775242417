<div class="po-row">
  
      <div class="sample-container" *ngIf="loading">
        <po-loading-overlay></po-loading-overlay>
      </div>
  
      <div class="sample-container" *ngIf="hasWarning">
        <p class="infoLabel" style="font-weight: bold;">ATENÇÃO!</p>
        <p class="infoLabel">{{ warnText }} </p>    
      </div>
      
  
    <po-dynamic-form
        #dynamicForm
        p-auto-focus="rule"
        [p-fields]="form_fields"
        [p-validate]="this.form_validateMain.bind(this)"
        [p-validate-fields]="validate_fields"
        [p-value]="form_values"
        >
    </po-dynamic-form>
    
    </div>