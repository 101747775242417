export class MenuRoute {
    public id: string;
    public userType: string[];
    public label: string;
    public shortLabel: string;
    public action: string;
    public link: string;
    public locale: string;
    public icon: string;
    public subItems: Object[];
    public position: string;
    public published: boolean;
}
