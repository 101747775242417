import { ChangeDetectorRef, Component, ViewChild } from '@angular/core';
import { PoModalAction, PoModalComponent, PoNotificationService, PoTableAction, PoTableColumn, PoTableLiterals, PoToasterType } from '@po-ui/ng-components';
import { PoPageDynamicSearchLiterals } from '@po-ui/ng-templates';
import { Issue } from './models/issue.model';
import { QueryDocument } from './models/querydocument.model';
import { IssueService } from './services/issue.service';
import * as ExcelJS from 'exceljs';
import { saveAs } from 'file-saver';
import { QueryManagerService } from './query-manager.service';
import { MainService } from 'src/app/app.service';

@Component({
  selector: 'app-query-manager',
  templateUrl: './query-manager.component.html',
  styleUrls: ['./query-manager.component.css']
})
export class QueryManagerComponent {
  customLiterals: PoTableLiterals = {
    loadMoreData: 'Buscar mais dados',
    loadingData: 'Processando',
    noColumns: 'Sem colunas',
    noData: 'Sem dados',
    seeCompleteSubtitle: 'Mostrar legenda completa',
    completeSubtitle: 'Todas legendas'
  };
  statusTest: String;
  queries: Array<Object>;
  tabs: Array<any> = [];
  tab: string = '';
  rowsQueries: Array<Object>;
  rowsViewQueries: Array<Object>;
  limit: number = 10;
  columnsQueries: Array<PoTableColumn> = [
      { property: 'query_in', label: 'Full Query', width: '20%', visible: false},
      { property: 'query_in', label: 'Query In', width: '20%', visible: false},
      { property: 'label', label: 'Query' , width: '30%'},
      { property: 'source', label: 'Fonte', width: '10%' },
      { property: 'date', label: 'Data do Fonte', width: '10%', 
      type: 'date', format:'dd/MM/yyyy HH:mm:ss'},
      { property: 'squad', label: 'Equipe', width: '10%' },
      { property: 'criticidade', label: 'Criticidade', width: '10%' },
      { property: 'called', label: 'N. chamadas', width: '10%', visible: false},
      { property: '_timestamp', label: 'Data de execução', type: 'date', width: '10%'},
      { property: 'line', label: 'Linha', width: '10%' , visible: false},
      { property: 'modulo', label: 'Modulo', width: '10%', visible: false },
      { property: 'segmento', label: 'Segmento', width: '10%' , visible: false},
      { property: 'time_elapsed', label: 'Tempo', width: '10%', visible: false },
      { property: 'obs', label: 'Observação', width: '10%' , visible: false},      
      { property: 'planning', label: 'Planejado', width: '10%' , visible: true},      
      { property: 'issueCode', label: 'Jira', width: '10%' , visible: true},      
    ];
  isReadOnly: Boolean = true;
  isDisabled: Boolean = true;
  obsLabelButton: string = "Editar";
  obsTypeButton: string = "secondary";
  obsquery: string;
  fullquery: string;
  _id: string;
  msg: string;
  statusQuery: string = "pending";
  loading: boolean;

  btnCreateLoading: Boolean = false;
  btnCreateDisabled: Boolean = false;
  
  squads: Array<any> = [];
  detail: any;
  infoLoaded: boolean = true;
  hiringProcesses: Array<object>;
  hiringProcessesColumns: Array<PoTableColumn>;

  planning: string = '';

  highlightTexts: Array<any> = [
    '.*(WHERE).*(\\+|\\|\\|).*', 
    '.*(SELECT).*(\\*).*', 
    '.*(.*(D_E_L_E_T_)(\s?)[\\<\\>](\s?).*).*',
    '.*(WHERE).*(NOT LIKE|NOT IN|NOT).*',
    '.*((FROM).*(,.*)+(WHERE|JOIN)).*',
    '.*(~(NOT)( IN )).*',
    '.*(ORDER BY).*'
  ];

  resultquery: Array<Object>;

  actions_primary: Array<PoTableAction> = [
    { action: this.exportxlsx.bind(this), icon: 'po-icon-export', label: '.xlsx', disabled: false },
    { action: this.docTDN.bind(this), icon: 'po-icon-info', label: 'TDN', disabled: false }
  ];
  actions: Array<PoTableAction> = [
    { action: this.details.bind(this), icon: 'po-icon-info', label: 'Details', disabled: false }
  ];

  confirm: PoModalAction = {
    action: this.confirmModal.bind(this),
    label: 'Fechar',
    danger: false
  };

  messageAI: string = 'Deseja que a query seja analisada e melhorada com IA?\n \n A query será analisada e o resultado será registrado em um apoio no JIRA.';
  needAIAnalyzer: Boolean = false;

  showAIModel() {
    this.needAIAnalyzer = confirm(this.messageAI)
    if (this.needAIAnalyzer) this.btnIssueOnClick();
  }

  findIndex(id) {
    return this.rowsQueries.findIndex((x) => x['_id'] === id);
  }

  confirmModal() {
    let idx = this.findIndex(this.detail._id);

    if (this.rowsQueries[idx]["planning"] != this.planning) {
      this.rowsQueries[idx]["planning"] = this.planning
      this.refresh(this.tab);
    }
    this.poModal.close();
  }

  disalbledSelect() {
    return sessionStorage.getItem('Role') === 'user' ? true : false;
  }

  disalbledPlanning() {
    return false
    // if (this.planning.length > 0) { //sessionStorage.getItem('Role') === 'user' && 
    //   return true
    // } else {
    //   return false
    // }
  }

  helpSelect(){
    let user = sessionStorage.getItem('User');
    return this.disalbledSelect() ? user + ' você tem permissão apenas para ler este campo!' : 'Alter o status da query para: \nPendente - Se estiver em análise;\nAprovado - Se foi apresentado um justificativa válida;\nReprovado - Se necessita de correção;';
  }

  helpPlanning(){
    let user = sessionStorage.getItem('User');
    return this.disalbledSelect() ? user + ' você tem permissão apenas para ler este campo!' : 'Indique em qual release a melhoria da query está prevista.';
  }

  @ViewChild(PoModalComponent, { static: true }) poModal: PoModalComponent;

  readonly literals: PoPageDynamicSearchLiterals = {
    filterConfirmLabel: 'Aplicar',
    filterTitle: 'Filtro avançado',
    quickSearchLabel: 'Valor pesquisado:'
  };

  public readonly filters: Array<Object> = [
    { property: 'query', label: 'Query', gridColumns: 6 },
    { property: 'squad', label: 'Equipe', gridColumns: 6 },
    { property: 'file', label: 'Fonte', gridColumns: 6 },
    { property: 'queryStatus', label: 'Status', gridColumns: 6 }
  ];
  
  constructor(
    private IssueService: IssueService,
    public poNotification: PoNotificationService,
    public queryManagerService: QueryManagerService,
    public mainService: MainService,
    private cdr: ChangeDetectorRef
    ) { };
  
  ngOnInit() {
    this.rowsQueries = [];
    this.rowsViewQueries = [];
    this.loadData();
    this.loadRules();
    // this.loadColumns();
  }

  ngOnDestroy() {}

  onChangeDisclaimers(disclaimers) {
    const filter = {};
    disclaimers.forEach(item => {
      filter[item.property] = item.value;
    });
    this.searchItems(filter);
  }

  onAdvancedSearch(filter) {
    filter ? this.searchItems(filter) : this.resetFilters();
  }

  onQuickSearch(filter: Object) {
    filter ? this.searchItems({ query: filter }) : this.resetFilters();
  }

  private searchItems(filter: Object) {
    this.rowsQueries = this.filter(filter);
    this.rowsViewQueries = this.rowsQueries.slice(0, this.limit);
  }

  private resetFilters() {
    this.refresh(this.tab);
  }

  filter(filters) {
    let filteredItems = Object.keys(filters).length === 0 ? this.loadData(this.tab):[...this.rowsQueries];
    Object.keys(filters).forEach(filter => {
      filteredItems = filteredItems.filter(register =>
        register[filter].includes(filters[filter])
      );
    });
    return filteredItems;
  }

  updateQuery() {
    let toUpdate = {
      _id: this._id,
      planning: this.detail["planning"],
      obs: this.obsquery
    };
    this.queryManagerService.postQuery(toUpdate)
    .subscribe({
      next: ((response) => {
        this.msg = "Atualizado com sucesso!";
        }),
      error: ((msgError) => {
        this.mainService.handleError(msgError);
        this.loading = false;
      })
    })
    // this.refresh(this.tab);
  }

  docTDN() {
    let target:string = 'https://tdn.totvs.com.br/pages/viewpage.action?pageId=635065756';
    
    switch (this.tab) {
      case 'SELECT_AST':
        target = target+'#Boaspráticasparaescritadequeries-Nãouse*emsuasqueries';
        break;
      case 'D_E_L_E_T_AST':
        target = target+'#Boaspráticasparaescritadequeries-NãouseD_E_L_E_T_<>\'*\'!';
        break;
      case 'WHERE_CONCAT':
        target = target+'#';
        break;
      case 'WHERE_NOT':
        target = target+'#Boaspráticasparaescritadequeries-Eviteousode<>enegações(NOTLIKE,NOTIN,NOTetc)noWhere';
        break;
      case 'FROM_MULTI_TAB':
        target = target+'#Boaspráticasparaescritadequeries-EvitefazerSELECTdeduastabelas';
        break;
      case 'SELECT_IN':
        target = target+'#Boaspráticasparaescritadequeries-EviteousodeIN';
        break;
      case 'ORDER_BY':
        target = target+'#Boaspráticasparaescritadequeries-UseORDERBYapenasquandonecessário';
        break;
      default:
        break;
    }
    window.open(target,"_blank")
  }

  copyToClipboard() {
    navigator.clipboard.writeText(this.fullquery.replace(/<br>/g, '\n'));
  }

  get highlightedText() {
    return this.applyHighlight(this.fullquery);
  }

  applyHighlight(text: string) {
    // this.highlightTexts.forEach(highlight => {
    //   text = text.replace(new RegExp(highlight, 'g'), '<mark class="marked">$&</mark>');
    // })
    return text;
  }

  details(item) {
    const terms = ["FROM","WHERE","GROUP BY","INNER JOIN","LEFT JOIN","RIGHT JOIN","ORDER BY","UNION","AND","HAVING"];
    this.detail = item;
    this.fullquery = item.query_html;
    this.obsquery = item.obs ? item.obs: null;
    this._id = item._id;
    this.statusQuery = item.statusQuery;
    this.planning = item.planning;
    if ( this.detail['issueCode'] ) {
      this.checkIssue(this.detail.issueCode, this.detail._id)
    }
    if (sessionStorage.getItem('Role') === 'user' && this.statusQuery === 'approved') {
      this.isReadOnly = true
    } 
    this.poModal.open();
  }

  isApproved(row, index: number) {
    return true;
  }

  loadData(item='D_E_L_E_T_AST', today=true, days=0) {
    this.limit = 10;
    this.loading = true;
    this.rowsQueries = [];
    this.rowsViewQueries = [];
    this.queryManagerService.getQuery(item, today)
    .subscribe({
      next: ((data) => {
        this.squads = data['squads_list'];
        data = data['queries'];

        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i]['label'] = data[i]['query'].slice(0,40) + '...'
            data[i]['file'] = data[i]['file'] //.split('|')[1]
            data[i]['line'] = data[i]['line'] //.split('|')[1]
            data[i]['function'] = data[i]['function']//.split('|')[1]
            data[i]['_timestamp'] = new Date(data[i]['_timestamp'] * 1000);
            data[i]['_date'] = new Date(data[i]['_date'] * 1000);
            data[i]['planning'] = data[i]['planning'] ? data[i]['planning'] : '';
            this.rowsQueries.push(data[i])
          }
        } 
        this.loading = false;
        this.showMore();
        }),
      error: ((msgError) => {
        this.mainService.handleError(msgError);
        this.loading = false;
      })
    });
    return this.rowsQueries
  }

  changeLoading() {
    return !this.loading
  }

  refresh(item) {
    this.limit = 10;
    this.rowsQueries = [];
    this.rowsViewQueries = [];
    this.loadData(item)
    // this.columnsQueries = this.getColumns()
  }

  showMore() {
    this.limit += 10;
    this.rowsViewQueries = this.rowsQueries.slice(0, this.limit);
  }

  cliclSetting(item){
    this.tab = item
    this.refresh(item);
  }

  loadRules() { 
    this.tabs = this.queryManagerService.getRulesQueries()
    this.tab = this.tabs[0]['label'];
    this.cdr.detectChanges();
  }

  exportxlsx(): void {
    let element;
    let fileName: string;
    let date: any;

    date = new Date().toLocaleDateString('pt-BR')
    date = date.replace(/\//gi,'')
    
    fileName=`pontos-de-atencao-${date}`;
    element = document.getElementById('query_table');
    this.resultquery = []
    this.queryManagerService.getQuery(this.tab)
    .subscribe({
      next: ((data) => {
        this.squads = data['squads_list'];
        data = data['queries'];

        if (data.length > 0) {
          for (let i = 0; i < data.length; i++) {
            data[i]['label'] = data[i]['query'].slice(0,40) + '...'
            data[i]['file'] = data[i]['file'] //.split('|')[1]
            data[i]['line'] = data[i]['line'] //.split('|')[1]
            data[i]['function'] = data[i]['function']//.split('|')[1]
            data[i]['_timestamp'] = new Date(data[i]['_timestamp'] * 1000);
            data[i]['_date'] = new Date(data[i]['_date'] * 1000);
            data[i]['planning'] = data[i]['planning'] ? data[i]['planning'] : '';
            this.resultquery.push(data[i]);
          }
        }
        // const ws: XLSX.WorkSheet = XLSX.utils.json_to_sheet(this.resultquery);
        // const wb: XLSX.WorkBook = XLSX.utils.book_new();

        // XLSX.utils.book_append_sheet(wb,ws,this.tab);
        // XLSX.writeFile(wb, fileName);
        this.generateExcel(this.resultquery, fileName)
        }),
      error: ((msgError) => {
        this.mainService.handleError(msgError);
        this.loading = false;
      })
    })
  }

  generateExcel(data: any[], fileName: string): void {
    const workbook = new ExcelJS.Workbook();
    const worksheet = workbook.addWorksheet('Sheet 1');
    // Add headers
    const headers = Object.keys(data[0]);
    worksheet.addRow(headers);
    // Add data
    data.forEach((item) => {
      const row: any[] = [];
      headers.forEach((header) => {
        row.push(item[header]);
      });
      worksheet.addRow(row);
    });
    // Save the workbook to a blob
    workbook.xlsx.writeBuffer().then((buffer) => {
      const blob = new Blob([buffer], { type: 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet' });
      saveAs(blob, `${fileName}.xlsx`);
    });
  }

  btnFalseReport() {
    let reportquerydoc = new QueryDocument();
    // Configura os valores que serão enviados para o método de criação de issues
    reportquerydoc._id = this.detail._id;
    reportquerydoc.squad = this.detail.squad;
    reportquerydoc.source = this.detail.source;
    reportquerydoc.func = this.detail.function;
    reportquerydoc.line = this.detail.line;
    reportquerydoc.query = this.detail.query;
    reportquerydoc.modulo = this.detail.modulo;
    reportquerydoc.planning = this.detail.planning;
    reportquerydoc.area = sessionStorage.getItem('Area')
    reportquerydoc.user = sessionStorage.getItem('User')

      this.queryManagerService.reportQuery(reportquerydoc).subscribe({
        next: ((dados) => {
            this.detail.disabled = dados.value.isfalse
            this.poModal.close()
            let indexDetail = this.rowsQueries.indexOf(this.detail)
            this.rowsQueries.splice(indexDetail, 1);
            this.poNotification.success('Notificado como falso positivo, será revisado e removido do catálogo!')
            this.refresh(this.tab)
          }),
          error: ((msgError) => {
            this.poNotification.error('Ocorreu um erro ao gerar a issue')
          })
      });
  }

    // Clique do botão para gerar issues no JIRA //
  btnIssueOnClick() {
      let issuedetails = new Issue();
      let issueresult: Array<any> = [];
  
      // Configura os valores que serão enviados para o método de criação de issues
      issuedetails._id = this.detail._id;
      issuedetails.squad = this.detail.squad;
      issuedetails.source = this.detail.source;
      issuedetails.func = this.detail.function;
      issuedetails.line = this.detail.line;
      issuedetails.query = this.detail.query;
      issuedetails.modulo = this.detail.modulo;
      issuedetails.planning = this.detail.planning;
      issuedetails.area = sessionStorage.getItem('Area')
      issuedetails.user = sessionStorage.getItem('User')
      issuedetails._smarttest = this.needAIAnalyzer

      // altera configuração do botão "Create Issue" -> apresenta ícone loading enquanto a issue é criada
      this.btnCreateLoading = true;

      this.IssueService.createIssue(issuedetails)
      .subscribe({
        next: ((dados) => {
          issueresult.push(dados);

          // atualiza o código da issue, o status e a URL 
          this.detail.issueCode = issueresult[0].value.issueCode;
          this.detail.issueStatus = issueresult[0].value.issueStatus;
          this.detail.issueUrl = issueresult[0].value.issueUrl;
          this.detail.issueArea = sessionStorage.getItem('Area')
          this.detail.issueUser = sessionStorage.getItem('User')

          // this.showNotification(PoToasterType.Success, 'Issue ' + this.detail.issueCode + ' criada com sucesso!', 'Visualizar issue', issueresult[0].value.issueUrl);
          this.poNotification.success('Issue ' + this.detail.issueCode + ' criada com sucesso!')

          // altera configuração do botão "Create Issue": ao terminar de gerar a issue, retorna ao formato padrão
          this.btnCreateLoading = false;

          // se for gerada a issue corretamente, então desabilita o botão
          if (this.detail.issueCode === '') {
            this.btnCreateDisabled = false;
            this.btnCreateLoading = false;
          } else {
            this.btnCreateDisabled = true;
            this.btnCreateLoading = false;
          }

          }),
          error: ((msgError) => {
            this.btnCreateLoading = false;
            this.poNotification.error('Ocorreu um erro ao gerar a issue')
          })
      });
    }

    checkIssue(issuecode: String, queryid: String) {
      try {
        this.IssueService.checkIssue(issuecode, queryid).subscribe(
          dados => { 
            this.detail.issueCode = dados.value.issueCode;
            this.detail.​​issueStatus = ​dados.value.issueStatus;
            this.detail.​​issueUrl = ​​dados.value.issueUrl;
          });
      }catch {
        this.poNotification.error('Ocorreu um erro ao gerar a issue')
      }
  }

    showNotification(type: PoToasterType, message: string, actionlabel: string = "", issueurl: string = "") {

      this.poNotification[type](message)
    }
}
