import { Injectable } from '@angular/core';
import { HttpClient, HttpHeaders, HttpErrorResponse, HttpClientModule } from '@angular/common/http';
import { map, catchError, tap, retry } from 'rxjs/operators';
import { Observable, of, throwError, pipe } from 'rxjs';
import { MainService } from '../../app.service';

@Injectable()
export class ProtheusService {

    public iniCanEdit = [ 
        "MultiProtocolPort",
        "MultiProtocolPortSecure",
        "logprofiler",
        "maxstringsize",
        "topmemomega",
        "buildkillusers",
        "maxstringsize"
    ];

    constructor( private _http: HttpClient, private mainsService: MainService) {
    }

    getProtheus (): Observable<any> {
      return this._http.get('/api/v2/protheus/byUser/?fields=all', this.mainsService.httpOptions )
          .pipe(
                  retry(3),
                  map(protheus => {
                      return protheus;
                  }),
                  catchError(this.mainsService.handleError));
    }

    getProtheusbyID (protheusID): Observable<any> {
        return this._http.get('/api/v2/protheus/' + protheusID, this.mainsService.httpOptions )
            .pipe(
                    retry(3),
                    map(protheus => {
                        return protheus;
                    }),
                    catchError(this.mainsService.handleError));
    }

    restartProtheus (protheusID): Observable<any> {
        return this._http.get('/api/v2/protheus/restart/' + protheusID, this.mainsService.httpOptions )
            .pipe(
                    retry(3),
                    map(protheus => {
                        return protheus;
                    }),
                    catchError(this.mainsService.handleError));
    }

    getReleases(): Observable<any> {
        return this._http.get('/api/v2/releases', this.mainsService.httpOptions )
                    .pipe(
                            retry(3),
                            map(releases => {
                                return releases;
                            }),
                            catchError(this.mainsService.handleError));
      }

    getBinarios(release): Observable<any> {
        return this._http.get('/api/v2/binaries/?release=' + release, this.mainsService.httpOptions )
                    .pipe(
                            retry(3),
                            map(binarios => {
                                return binarios;
                            }),
                            catchError(this.mainsService.handleError));
    }

    getBinariosFilter(release, appserver, dbaccess): Observable<any> {
        return this._http.get('/api/v2/binaries/?release=' + release + '&appserver=' + appserver + '&dbaccess=' + dbaccess, this.mainsService.httpOptions )
                    .pipe(
                            retry(3),
                            map(binarios => {
                                return binarios;
                            }),
                            catchError(this.mainsService.handleError));
    }

    getDbAccess(release, appserver_version): Observable<any> {
    return this._http.get('/api/v2/dbaccess/?release=' + release + '&appserver=' + appserver_version, this.mainsService.httpOptions )
                .pipe(
                        retry(3),
                        map(dbaccess => {
                            return dbaccess;
                        }),
                        catchError(this.mainsService.handleError));
    }

    getLocalizacoes(release): Observable<any> {
    return this._http.get('/api/v2/locales/?release=' + release, this.mainsService.httpOptions )
                .pipe(
                        retry(3),
                        map(localizacoes => {
                            return localizacoes;
                        }),
                        catchError(this.mainsService.handleError));
    }

    getRPOs(release, localizacao): Observable<any> {
    return this._http.get('/api/v2/rpo/?release=' + release + '&localizacao=' + localizacao, this.mainsService.httpOptions )
                .pipe(
                        retry(3),
                        map(rpo => {
                            return rpo;
                        }),
                        catchError(this.mainsService.handleError));
    }

    getBases(release, localizacao): Observable<any> {
      let bases = this._http.get(
        `/api/v2/bases/?release=${release}&localizacao=${localizacao}`, 
        this.mainsService.httpOptions
        ).pipe(
            retry(3),
            map(data => {
            return data;
            }),
            catchError(this.mainsService.handleError)
        );

      let templates = this._http.get(
        `/api/v2/templates/?release=${release}&locale=${localizacao}`, 
        this.mainsService.httpOptions
        ).pipe(
            retry(3),
            map(data => {
            return data;
            }),
            catchError(this.mainsService.handleError)
        );

      return new Observable((subscriber) => {
        bases.subscribe((result) => {
          let result2 = result as Array<{ kind?: string }>;
          for (let i=0; i<result2.length; i++) result2[i].kind = "base";
          subscriber.next(result2);
        });

        templates.subscribe((result) => {
          let result2 = result as Array<{ kind?: string }>;
          for (let i=0; i<result2.length; i++) result2[i].kind = "template";
          subscriber.next(result2);
        });
      });
    }

    getBasebyID (baseID): Observable<any> {
        return this._http.get('/api/v2/bases/' + baseID, this.mainsService.httpOptions )
            .pipe(
                    retry(3),
                    map(base => {
                        return base;
                    }),
                    catchError(this.mainsService.handleError));
    }

    // {
    //   "name" : "TesteProtheus",
    //   "username" : "vinicius.fragazi",
    //   "release_id" : "618d2645a24176cddc40ece1",
    //   "appserver_id" : "618d2ca4a24176cddc40ed45",
    //   "dbaccess_id" : "618d2fe4a24176cddc40ed80",
    //   "localizazao_id" : "619509e83500853f86eca603",
    //   "rpo_id" : "6197e8b4678c9e08641274a7",
    //   "rest": true,
    //   "sgdbType": "POSTGRES"
    
    // }

    createProtheus (name, release_id, appserver_id, dbaccess_id, localizazao_id, rpo_id, rest, mpp, sgdbType, expirationDays): Observable<any> {
        const username = this.mainsService.userToken.username;

        return this._http.post('/api/v2/protheus/', 
            JSON.stringify({ 
                name, 
                username, 
                release_id, 
                appserver_id, 
                dbaccess_id,
                localizazao_id,
                rpo_id,
                rest,
                mpp,
                sgdbType,
                expirationDays}), this.mainsService.httpOptions)
            .pipe(
                map(res => {
                    return res;
            }),
                catchError(this.mainsService.handleError)
            );
    }

    createProtheus2(name, release_id, appserver_id, dbaccess_id, localizazao_id, rpo_id, rest, mpp, sgdbType, expirationDays): Observable<any> {
        const username = this.mainsService.userToken.username;

        return this._http.post('/api/v2/protheus/', 
            JSON.stringify({ 
                name, 
                username, 
                release_id, 
                appserver_id, 
                dbaccess_id,
                localizazao_id,
                rpo_id,
                rest,
                mpp,
                templateId: sgdbType,
                expirationDays}), this.mainsService.httpOptions)
            .pipe(
                map(res => {
                    return res;
            }),
                catchError(this.mainsService.handleError)
            );
    }

    updateProtheus (id, name, days): Observable<any> {
        this.mainsService.updateHeaders();
        let dataToUpdate = {}
        if (days > 0){
            dataToUpdate = { name, days}
        } else {
            dataToUpdate = { name }
        }
        return this._http.put('/api/v2/protheus/' + id, JSON.stringify(dataToUpdate), this.mainsService.httpOptions)
            .pipe(
                map( res => {
                    return res;
                }),
                    catchError(this.mainsService.handleError)
            );
    }

    shareProtheus(id, shared): Observable<any> {
        return this._http.put('/api/v2/protheus/' + id, JSON.stringify({ shared }), this.mainsService.httpOptions)
            .pipe(
                map(res => {
                    return res;
                }),
                catchError(this.mainsService.handleError)
            );
    }

    deleteProtheus (id): Observable<any> {
        return this._http.delete('/api/v2/protheus/' + id, this.mainsService.httpOptions)
        .pipe(
            catchError(this.mainsService.handleError)
        );
    }

    getINItoEdit (id): Observable<any> {
        return this._http.get('/api/v2/protheus/ini/' + id, this.mainsService.httpOptions )
            .pipe(
                    retry(3),
                    map(iniEdit => {
                        return iniEdit;
                    }),
                    catchError(this.mainsService.handleError));
    }

    saveINIEdited (id, modifiedIni): Observable<any> {
        return this._http.put('/api/v2/protheus/ini/' + id, JSON.stringify(modifiedIni), this.mainsService.httpOptions )
                .pipe(
                    map(res => {
                        return res;
                    }),
                    catchError(this.mainsService.handleError)
                );
    }

    getConfigsToEdit (): Observable<any> {
    return this._http.get('/api/v2/config?config=iniEdit', this.mainsService.httpOptions )
        .pipe(
                retry(3),
                map(iniEdit => {
                    return iniEdit;
                }),
                catchError(this.mainsService.handleError));
    }
}
