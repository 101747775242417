import { Component } from '@angular/core';
import { NotificationServiceHM } from './notification-item.service';
import { Subscription } from 'rxjs';
import { CommonModule } from '@angular/common';

@Component({
  selector: 'notificationComponentHM',
  standalone: true,
  templateUrl: './notification-item.component.html',
  styleUrls: ['./notification-item.component.css'],
  imports: [CommonModule]
})
export class NotificationItemComponent {

  message: string = '';
  show: boolean = false;
  subscription: Subscription;
  toasterBackgroundColor: string = ''
  toasterIconBackgroundColor: string = ''
  icon: string = '';

  constructor(private notificationServiceHM: NotificationServiceHM) { }

  ngOnInit(): void {
    this.subscription = this.notificationServiceHM.getToastObservable()
      .subscribe(message => {
        this.message = message.text;
        switch (message.type) {
          case 'success':
            this.toasterBackgroundColor = 'var(--background-success)'
            this.toasterIconBackgroundColor = 'var(--color-success)'
            this.icon = 'po-icon po-icon-ok';
            break;
          
          case 'warning':
            this.toasterBackgroundColor = 'var(--background-warning)'
            this.toasterIconBackgroundColor = 'var(--color-warning)'
            this.icon = 'po-icon po-icon-warning';
            break;
    
          case 'error':
            this.toasterBackgroundColor = 'var(--background-error)'
            this.toasterIconBackgroundColor = 'var(--color-error)'
            this.icon = 'po-icon po-icon-exclamation';
            break;
        }

        this.show = true;
        setTimeout(() => this.show = false, 5000);
    });
  }

  defineType(type){
    
  }
  closeToaster(){
    this.show = false;
  }

  ngOnDestroy() {
    if (this.subscription) {
      this.subscription.unsubscribe();
    }
  }
}